import { get } from "@/utils/http";
export const getJournal = (params) => get("index/api/v1/journals", params);
export const getJournalDetail = (params) =>
  get(`${params.path}/api/v1/journals/detail`);
export const getIndexing = (params) =>
  get(`${params.path}/api/v1/journals/indexing`);
// export const getJournalAbout = (params) => get("journals/about", params);
export const getIssues = (params) => get(`${params.path}/api/v1/_issues`);
export const getIssuesDetail = (params) =>
  get(`${params.path}/api/v1/_issues/${params.id}`);
export const getCurrentIssues = (params) =>
  get(`${params.path}/api/v1/_issues/current`);
export const getAnnouncements = (params) =>
  get(`${params.path}/api/v1/announcements`, params);
export const getAnnouncementsDetail = (params) =>
  get(`${params.path}/api/v1/announcements/${params.id}`);
export const editorialTeam = (params) =>
  get(`${params.path}/api/v1/journals/editorialTeam`);
export const authorGuidelines = (params) =>
  get(`${params.path}/api/v1/submissions/check/list`);
export const getAPCs = (params) => get(`${params.path}/api/v1/journals/APCs`);
export const getContact = (params) =>
  get(`${params.path}/api/v1/journals/contact`);
export const getPrivacy = (params) =>
  get(`${params.path}/api/v1/journals/privacy`);
export const ensuringBlindReview = (params) =>
  get(`${params.path}/api/v1/journals/ensuringBlindReview`);
export const authorship = (params) =>
  get(`${params.path}/api/v1/journals/authorship`);
// export const getArticles = (params) => get("articles/detail", params);
