<template>
  <div class="home">
    <div class="centerHome">
      <div class="banner">
        <div class="bannerText animate__animated animate__fadeInUp">
          <div class="bannerDesc">Most Trusted. Most Cited. Most Read</div>
          <div class="bannerTitle">PiscoMed Publishing Welcome Cooperation</div>
        </div>
        <div class="search animate__animated animate__fadeInDown">
          <div class="searchInput">
            <el-input
              placeholder="Please enter the journal title"
              v-model="input"
              class="input-with-select"
            >
            </el-input>
          </div>
          <div style="margin-left: 20px" class="selectBtn">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              >search</el-button
            >
          </div>
        </div>
      </div>
      <div class="centerHome">
        <div class="journalList">
          <h2 class="bookTitle animate__animated animate__fadeInLeft">
            Journals
          </h2>
          <div class="journalBox">
            <div class="card-container">
              <div
                class="card"
                v-for="(item, index) in journalList"
                :key="index"
              >
                <img
                  :src="item.thumbnail.url"
                  alt="jounral"
                  class="card-image"
                />
                <h3 class="card-title">
                  <!-- @click="toDetailPages(item)" -->
                  <a :href="journalLink(item)">{{ item.title }}</a>
                </h3>
                <div class="description">
                  <p class="card-description" v-if="item.onlineIssn">
                    eISSN:
                    {{ item.onlineIssn }}
                  </p>
                  <p class="card-description" v-if="item.printIssn">
                    &nbsp;ISSN: {{ item.printIssn }}
                  </p>
                </div>
                <div class="view-list">
                  <!-- @click="toDetailPages(item)" -->
                  <span tabindex="0"
                    ><a :href="journalLink(item)">Read More</a>&nbsp;&nbsp;<i
                      class="el-icon-arrow-right"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="view-list"
            @click="toJournalList"
            v-if="journalList.length > 0"
          >
            <span tabindex="0"
              >VIEW LIST OF JOURNALS&nbsp;&nbsp;<i
                class="el-icon-arrow-right"
              ></i
            ></span>
          </div>
        </div>
        <div class="articleList">
          <h2 class="bookTitle animate__animated animate__fadeInLeft">
            Latest Articles
          </h2>
          <div class="box" :class="{ visible: visible }">
            <div class="articleBox">
              <!-- @click="toArticleDetail(item)" -->
              <div
                class="articleItem"
                v-for="(item, index) in articleList.slice(0, 8)"
                :key="index"
              >
                <div class="label">
                  <div class="labelText commonBg">
                    <img src="@/assets/opimg/suob.png" alt="" />
                    Open Access
                  </div>
                  <div class="labelText articleType">{{ item.section }}</div>
                </div>
                <div class="journalName">{{ item.journalTitle }}</div>
                <div class="onlineTime">
                  <i class="el-icon-date"></i>{{ item.datePublished }}
                </div>
                <div class="articleTitle" style="text-align: left">
                  <a :href="articleLink(item)">{{ item.fullTitle }}</a>
                </div>
                <!-- <div class="articleType">{{ item.section }}</div> -->
                <!-- <br /> -->
                <!-- <div class="doi">{{ item.doi }}</div> -->
              </div>
            </div>
            <div
              class="view-list"
              v-if="articleList.length > 0"
              @click="toJournalList"
            >
              <span tabindex="0"
                >VIEW LIST OF JOURNALS&nbsp;&nbsp;<i
                  class="el-icon-arrow-right"
                ></i
              ></span>
            </div>
          </div>
        </div>
        <div class="newsList">
          <h2 class="bookTitle animate__animated animate__fadeInLeft">
            News & Announcements
          </h2>
          <div class="box">
            <div class="newsBox">
              <div
                class="newsItem"
                v-for="(item, index) in newsList"
                :key="index"
              >
                <!-- @click="toNewsDetail(item)" -->
                <div class="newsName">
                  <a :href="newsLink(item)">{{ item.title }}</a>
                </div>
                <div class="journalName">{{ item.journalName }}</div>
                <div class="onlineTime">
                  <i class="el-icon-date"></i>{{ item.datePosted }}
                </div>
                <div
                  class="newsDesc"
                  style="text-align: left"
                  v-html="item.descriptionShort"
                ></div>
              </div>
            </div>
            <div
              class="view-list"
              v-if="newsList.length > 0"
              @click="toAnnouncementsList"
            >
              <span tabindex="0"
                >VIEW LIST OF NEWS&nbsp;&nbsp;<i class="el-icon-arrow-right"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getJournal } from "@/api/journal";
import { allLatest } from "@/api/submissions";
import { getNews } from "@/api/common";
import { addStyles } from "@/utils/addStyle";
import { journalLink, articleLink, newsLink } from "@/utils/addLink";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      input: "",
      select: "1",
      journalList: [],
      articleList: [],
      // loading: false,
      // loadArticles: false,
      visible: false,
      newsList: [],
    };
  },
  mounted() {
    this.getJournalList();
    this.getArticlesList();
    this.getNewsList();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.visible = true;
          observer.unobserve(entry.target);
        }
      });
    });

    const target = this.$el.querySelector(".articleBox");
    observer.observe(target);
  },
  methods: {
    journalLink,
    articleLink,
    newsLink,
    getJournalList() {
      getJournal({ count: 6, isIndex: true }).then((res) => {
        if (res.code == 200) {
          this.journalList = res.data.items;
          this.$nextTick(() => {
            const p = document.querySelectorAll(".journalDesc p");
            p.forEach((p) => {
              p.style.lineHeight = "1.5rem";
            });
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getArticlesList() {
      // this.loadArticles = true;
      allLatest().then((res) => {
        if (res.code == 200) {
          this.articleList = res.data.items;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getNewsList() {
      getNews({ count: 4, page: this.page })
        .then((res) => {
          if (res.code == 200) {
            this.newsList = res.data.items;
            this.$nextTick(() => {
              let pList = document.querySelectorAll(".newsDesc p");
              Array.from(pList).forEach((p) => {
                p.style.lineHeight = "22px";
                p.style.fontSize = "15px";
              });
              addStyles(".newsDesc");
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    toJournalList() {
      this.$router.push({
        path: "/journalList",
      });
    },
    toAnnouncementsList() {
      this.$router.push({
        path: "/announcementsList",
      });
    },
    toDetailPages(item) {
      localStorage.setItem("journal", JSON.stringify(item.path));
      this.$router.push({
        path: "/detailPages/" + item.path,
        query: {
          journal: item.path,
        },
      });
    },
    toArticleDetail(item) {
      this.$router.push({
        path: "/detailPages/" + item.journalPath + "/articalDetail",
        query: {
          journal: item.journalPath,
          id: item.id,
        },
      });
    },
    toNewsDetail(item) {
      this.$router.push({
        name: "announcementsDetail",
        query: {
          detailUrl: item.url,
        },
      });
    },
    handleSearch() {
      this.$router.push({
        name: "journalList",
        query: {
          searchCriteria: this.input,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/commen.css");
.home {
  background-color: #ffffff;
  .centerHome {
    width: 98%;
    max-width: 1400px;
    margin: auto;
    .banner {
      position: relative;
      height: 270px;
      background: url("@/assets/images/banner.jpg") #ffffff no-repeat center;
      background-size: 100% 100%;
      box-shadow: #ececec 0px 0px 10px;
      overflow: hidden;
      z-index: 1;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 200px;
      }
      .bannerText {
        color: #ffffff;
        text-align: center;
        .bannerDesc {
          font-size: 2.2em;
          font-weight: bold;
          @media only screen and (max-width: 600px) {
            font-size: 1.5em;
          }
          @media only screen and (min-width: 601px) and (max-width: 1200px) {
            font-size: 1.8em;
          }
        }
        .bannerTitle {
          font-size: 1.5em;
          line-height: 3.2em;
          @media only screen and (max-width: 600px) {
            font-size: 0.9em;
            line-height: 2.6em;
          }
          @media only screen and (min-width: 601px) and (max-width: 1200px) {
            font-size: 0.9em;
            line-height: 2.8em;
          }
        }
      }
      .search {
        display: flex;
        justify-content: center;
        .searchInput {
          width: 25em;
          @media only screen and (max-width: 600px) {
            width: 15em;
          }
          /* 中等屏幕设备 */
          @media only screen and (min-width: 601px) and (max-width: 1200px) {
            width: 20em;
          }
        }
        .selectBtn {
          .el-button {
            padding: 14px 42px;
            font-size: 20px;
            border-radius: 8px !important;
            background-image: -webkit-gradient(
              linear,
              left bottom,
              left top,
              color-stop(0, #64b5f6),
              color-stop(1, #1e88e5)
            );
            @media (max-width: 996px) {
              padding: 14px 10px;
            }
          }
        }
      }
    }
    .banner:before {
      position: absolute;
      content: " ";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(65, 73, 89, 0.65);
      z-index: -1;
    }
    .centerHome {
      padding: 10px 0;
      @media (max-width: 996px) {
        width: 100vw;
        max-width: 100%;
        margin: 0 4px;
      }

      .journalList {
        .titleBox {
          padding-bottom: 10px;
          position: relative;
          width: 100%;
          margin: 0 auto;
          margin-bottom: 20px;
          z-index: 1;
          .title {
            font-size: 40px !important;
            font-weight: 550px;
            text-align: center;
            padding: 6px 0;
            color: #000;
          }
        }
        .titleBox ::after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          bottom: 0;
          left: 12%;
          right: 12%;
          height: 100%;
          box-shadow: 0 1.25rem 1.25rem -1.25rem rgb(11 11 11 / 24%);
        }
        .journalBox {
          min-height: 200px;
          .card-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 13px;
            @media (max-width: 500px) {
              gap: 8px;
            }
            .card {
              background-color: white;
              border: 1px solid #ddd;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              width: 197px;
              padding: 8px 9px;
              @media (max-width: 375px) {
                width: 150px;
                padding: 7px 8px;
              }
              @media (min-width: 376px) and (max-width: 420px) {
                width: 165px;
                padding: 7px 8px;
              }
              &:hover {
                box-shadow: #585858 0px 0px 10px;
                transition: all 0.6s;
                .card-title {
                  cursor: pointer;
                  color: #098acc;
                  text-decoration: underline;
                }
              }
              .card-image {
                width: 197px;
                height: 290px;
                border-radius: 8px 8px 0 0;
                @media (max-width: 375px) {
                  width: 150px;
                  height: 270px;
                }
                @media (min-width: 376px) and (max-width: 420px) {
                  width: 165px;
                  height: 280px;
                }
              }
              .card-title {
                font-size: 16px;
                margin: 8px 0;
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              .description {
                height: 60px;
                .card-description {
                  font-size: 0.9em;
                  color: #555;
                }
              }

              .view-list {
                cursor: pointer;
                color: #fff;
                border-radius: 4px;
                margin-top: 3px;
                text-align: left;
                span {
                  background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    left top,
                    color-stop(0, #64b5f6),
                    color-stop(1, #1e88e5)
                  );
                  display: inline-block;
                  padding: 8px 8px;
                  border-radius: 8px;
                  &:hover {
                    color: #fff;
                    i {
                      display: inline-block;
                      animation: leftRightMoveIn 0.8s ease-in-out 0s infinite;
                      @keyframes leftRightMoveIn {
                        0% {
                          transform: translateX(-100%);
                        }
                        50% {
                          transform: translateX(0);
                        }
                        100% {
                          transform: translateX(100%);
                        }
                      }
                    }
                  }
                  &:focus {
                    box-shadow: inset 0 0 0 0.0625rem #218ae5,
                      0 0 0.25rem 0.125rem #218ae5 !important;
                  }
                }
              }
            }
          }
        }
      }
      .bookTitle {
        font-size: 36px;
        font-weight: 520;
        color: #000;
        margin: 0 0 15px 0;
        position: relative;
        z-index: 1;
        font-family: initial;
      }
      .bookTitle:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: -20px;
        bottom: 0;
        width: 104px;
        width: 6.5rem;
        max-width: 90%;
        height: 20px;
        height: 1.25rem;
        background-color: #ebebeb;
      }
      .view-list {
        margin-top: 20px;
        cursor: pointer;
        text-align: right;
        color: #3295ea;
        border-radius: 4px;
        span {
          display: inline-block;
          padding: 10px 20px;
          &:hover {
            color: #3295ea;
            i {
              display: inline-block;
              animation: leftRightMoveIn 0.8s ease-in-out 0s infinite;
              @keyframes leftRightMoveIn {
                0% {
                  transform: translateX(-100%); /* 从左边开始 */
                }
                50% {
                  transform: translateX(0); /* 移动到原始位置 */
                }
                100% {
                  transform: translateX(100%); /* 向右移动 */
                }
              }
            }
          }
          &:focus {
            box-shadow: inset 0 0 0 0.0625rem #218ae5,
              0 0 0.25rem 0.125rem #218ae5 !important;
          }
        }
      }
      .articleList {
        .titleBox {
          width: 100%;
          text-align: center;
          padding: 20px 0;
          .title {
            width: 100%;
            font-size: 30px;
            background-image: linear-gradient(135deg, #64b5f6, #1e88e5);
            -webkit-background-clip: text;
            color: transparent;
            display: inline;
            letter-spacing: 2px;
          }
        }
        .box {
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 1.5s ease, transform 1.5s ease;
          .articleBox {
            display: flex;
            flex-wrap: wrap;
            min-height: 200px;
            .articleItem {
              transition: all 0.6s;
              width: calc(25% - 30px);
              padding: 20px;
              box-sizing: border-box;
              margin: 15px;
              border: 1px solid #dedede;
              border-radius: 8px;
              box-shadow: #838383 0px 0px 1px;
              @media (max-width: 996px) {
                width: 100%;
                height: auto;
                padding: 10px;
                margin: 10px;
              }
              .label {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                @media (max-width: 996px) {
                  margin-bottom: 8px;
                }
                .labelText {
                  border-radius: 6px;
                  line-height: 25px;
                  color: #fff;
                  padding: 0 10px;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  margin-right: 10px;
                  img {
                    width: auto;
                    height: 15px;
                    margin-right: 5px;
                    @media (max-width: 996px) {
                      height: 10px;
                      margin-right: 3px;
                    }
                  }
                }
                .articleType {
                  background: #ffa500;
                }
              }
              .journalName {
                font-size: 14px;
                color: #0fa3bc;
                margin-bottom: 5px;
              }
              .onlineTime {
                color: #5b5b5b;
                font-size: 14px;
                margin-bottom: 5px;
                i {
                  margin-right: 5px;
                }
              }
              .articleTitle {
                font-size: 15px;
                font-weight: 600;
                justify-content: space-between;
                line-height: 1.3;
                margin-bottom: 5px;
                cursor: pointer;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .authors {
                font-size: 13px;
                color: #5b5b5b;
                margin-bottom: 10px;
              }
              .doi {
                color: #969696;
                font-size: 13px;
                word-wrap: break-word;
              }
            }
            .articleItem:hover {
              box-shadow: #585858 0px 0px 10px;
              transition: all 0.6s;
              .articleTitle {
                color: #098acc;
                text-decoration: underline;
              }
            }
          }
        }
        .visible {
          opacity: 1; /* 可见时的状态 */
          transform: translateY(0); /* 回到原始位置 */
        }
      }
      .newsList {
        .newsBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          min-height: 200px;
          .newsItem {
            width: 100%;
            padding: 20px;
            margin: 10px;
            border: 1px solid #dedede;
            border-radius: 8px;
            box-shadow: #838383 0px 0px 1px;
            transition: all 0.6s;
            // .newsImg {
            //   width: 100%;
            //   height: 180px;
            //   object-fit: cover;
            //   border-radius: 8px 8px 0 0;
            // }
            .newsName {
              font-size: 15px;
              font-weight: 600;
              justify-content: space-between;
              line-height: 1.3;
              margin-bottom: 5px;
              cursor: pointer;
            }
            .journalName {
              font-size: 14px;
              color: #0fa3bc;
              margin-bottom: 5px;
            }
            .newsDesc {
              line-height: 2;
              font-size: 13px;
              color: #5b5b5b;
              margin-bottom: 10px;
            }
            .onlineTime {
              color: #969696;
              font-size: 13px;
              margin-bottom: 10px;
            }
          }
          .newsItem:hover {
            box-shadow: #585858 0px 0px 10px;
            transition: all 0.6s;
            .newsName {
              color: #098acc;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
::v-deep .input-with-select .el-input__inner {
  height: 50px !important;
  line-height: 50px !important;
}
@media (max-width: 996px) {
  h1 {
    font-size: 20px;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    height: 26px;
    line-height: 25px;
    width: 25px;
  }
  .el-input__inner {
    height: 30px !important;
  }
  .el-input--medium .el-input__inner,
  .el-input__inner {
    height: 100% !important;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    height: 100% !important;
  }
}
</style>
