import "@/assets/css/commen.css";
export function addStyles(doc) {
  let links = document.querySelectorAll(`${doc} a`);
  let ulStyle = document.querySelectorAll(`${doc} ul, ${doc} ol`);
  let liStyle = document.querySelectorAll(`${doc} li`);
  let tableStyle = document.querySelectorAll(`${doc} table`);
  let tdStyle = document.querySelectorAll(`${doc} th, ${doc} td`);
  let images = document.querySelectorAll(`${doc} img`);
  Array.from(links).forEach((i) => {
    i.classList.add("linkStyle");
  });
  Array.from(ulStyle).forEach((ul) => {
    ul.classList.add("ulStyle");
  });
  Array.from(liStyle).forEach((li) => {
    li.classList.add("liStyle");
  });
  Array.from(tableStyle).forEach((table) => {
    table.classList.add("tableStyle");
  });
  Array.from(tdStyle).forEach((td) => {
    td.classList.add("tdStyle");
  });
  Array.from(images).forEach((image) => {
    image.classList.add("imgStyle");
  });
}
