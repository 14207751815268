<template>
  <div>
    <div id="app ">
      <div
        class="app"
        v-if="$route.name !== 'login' && $route.name !== 'regist'"
      >
        <HomeHeader />
        <router-view />
        <HomeFooter />
      </div>
      <router-view v-else />
    </div>
    <el-backtop :visibility-height="200" :duration="300">
      <div>
        <!-- animate__animated animate__flash animate__infinite -->
        <i class="el-icon-caret-top"></i>
      </div>
    </el-backtop>
  </div>
</template>
<script>
import HomeHeader from "./components/HomeHeader";
import HomeFooter from "./components/bottomView";
export default {
  name: "App",
  components: {
    HomeHeader,
    HomeFooter,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  beforeDestroy() {
    localStorage.clear();
  },
  mounted() {},
};
</script>

<style lang="less">
@import url("https://at.alicdn.com/t/c/font_4650831_qb2uq81rba.css");
* {
  margin: 0;
  padding: 0;
  font-family: "Arial";
}
.app {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 350px);
  background-color: #f0f0f0;
  padding-top: 75px;
  padding-bottom: 275px;
  display: flex;
  flex-direction: column;
  @media (max-width: 996px) {
    padding-top: 75px;
  }
}
a {
  text-decoration: none;
  color: inherit;
}

/* 为所有具有滚动条的元素自定义滚动条样式 */
html {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-track {
    background-color: #eee;
  }
  // scrollbar-width: thin; /* 设置滚动条宽度为较窄的宽度 */
  // scrollbar-color: #888 #f1f1f1; /* 设置滚动条滑块颜色和轨道颜色 */
}
li {
  // list-style: none;
}
</style>
<style scoped>
.el-backtop {
  background: linear-gradient(to bottom, #218ae5, #3a9aec, #63b4f6);
  color: #fff;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  /* box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.3),
    -8px -8px 15px rgba(255, 255, 255, 0.4); */
}
</style>
