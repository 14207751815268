import service from "./service";

// 封装 get, post, delete, put方法

export function get(url, params) {
  if (params && params.apiToken) {
    let token = localStorage.getItem("token");
    params.apiToken = token;
  }
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
        // Loading.axios(true).close();
        // Message({message: '请求成功', type: 'success'});
      })
      .catch((err) => {
        reject(err);
        // Loading.axios(true).close();
        // Message({message: '加载失败', type:'error'});
      });
  });
}

export function post(url, data, params) {
  if (params && params.apiToken) {
    let token = localStorage.getItem("token");
    params.apiToken = token;
  }
  return new Promise((resolve, reject) => {
    service
      .post(url, data, {
        params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }) // params 放在 config 中，data 作为请求体数据
      .then((res) => {
        resolve(res);
        // Loading.service(true).close();
        // Message({ message: '请求成功', type: 'success' });
      })
      .catch((err) => {
        reject(err);
        // Loading.service(true).close();
        // Message({ message: '加载失败', type: 'error' });
      });
  });
}
