import axios from "axios";
import { Message, MessageBox } from "element-ui";
const service = axios.create({
  // baseURL: "http://ceshi.piscomed.com/index.php/",
  baseURL: "https://ojs.piscomed.com/index.php/",
  timeout: 50000,
});
service.interceptors.response.use(
  function (response) {
    const contentType = response.headers["content-type"];
    if (contentType && contentType.includes("application/pdf")) {
      return response;
    } else if (response.status == 200) {
      const res = response.data;
      if (res.code == 200 || res.code == 401 || res.code == 500) {
        return res;
      } else if (res.code == 402) {
        MessageBox.alert(
          "Your session has expired, please log in again.",
          "Session expiration",
          {
            confirmButtonText: "Sure",
            callback: () => {
              window.location.href = "/login";
            },
          }
        );
        return res;
      } else {
        Message({
          type: "error",
          message: res.message || "Request error",
        });
        return Promise.reject(new Error(res.message || "Request error"));
      }
    } else {
      return Promise.reject(new Error("Unexpected response status"));
    }
  },
  function (error) {
    if (error.code === "ECONNABORTED") {
      Message({
        type: "error",
        message: "Request timed out, please try again later",
      });
      //   console.error("Request timed out, please try again later");
      return retryRequest(error.config);
    }
    Message({
      type: "error",
      message: error.message || "Request error",
    });
    return Promise.reject(error);
  }
);
function retryRequest(config, attempt = 0, retries = 3) {
  return new Promise((resolve, reject) => {
    attempt++;
    service(config)
      .then(resolve)
      .catch((error) => {
        if (attempt <= retries) {
          retryRequest(config, attempt, retries).then(resolve).catch(reject);
        } else {
          Message({
            type: "error",
            message: error.message || "Request failed after multiple attempts",
          });
          //   console.error(
          //     error.message || "Request failed after multiple attempts"
          //   );
          reject(error);
        }
      });
  });
}
export default service;
