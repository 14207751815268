import { get, post } from "@/utils/http";
export const articleDetail = (params) =>
  get(`${params.path}/api/v1/submissions/${params.id}/detail`, params);
export const allLatest = () => get("/index/api/v1/submissions/allLatest");
export const searchList = (params) =>
  get(`${params.path}/api/v1/submissions/search/list`, params);

//submitStep
export const firstStep = (params) =>
  get(`${params.path}/api/v1/_submissions/step1`, params);
export const submitFirstStep = (data, params) =>
  post(`${params.path}/api/v1/_submissions/step/1`, data, params);
export const getSecondStep = (params) =>
  get(`${params.path}/api/v1/_submissions/step2`, params);
export const submitSecondStep = (data, params) =>
  post(`${params.path}/api/v1/_submissions/step/2`, data, params);
export const submitThirdStep = (data, params) =>
  post(`${params.path}/api/v1/_submissions/step/3`, data, params);
export const submitFourStep = (data, params) =>
  post(`${params.path}/api/v1/_submissions/step/4`, data, params);
export const getMetadata = (params) =>
  get(`${params.path}/api/v1/_submissions/metadata`, params);

//submissions
export const getSubmissions = (params) =>
  get(`${params.path}/api/v1/_submissions`, params);
export const getSubmissionsAll = (params) =>
  get(`${params.path}/api/v1/_submissions/userAll`, params);
export const getSubmissionsDetail = (params) =>
  get(`${params.path}/api/v1/_submissions/detail`, params);
export const submissionsDestroy = (data, params) =>
  post(`${params.path}/api/v1/_submissions/destroy`, data, params);

//discussions
export const getDiscussions = (params) =>
  get(`${params.path}/api/v1/_submissions/discussions`, params);
export const getDiscussionsDetail = (params) =>
  get(`${params.path}/api/v1/_submissions/discussions/detail`, params);
export const getDiscussionsEdit = (params) =>
  get(`${params.path}/api/v1/_submissions/discussions/edit`, params);
export const discussionsUpdate = (data, params) =>
  post(`${params.path}/api/v1/_submissions/discussions/update`, data, params);
export const discussionsDestroy = (data, params) =>
  post(`${params.path}/api/v1/_submissions/discussions/destroy`, data, params);

//Messages
export const getMessages = (params) =>
  get(`${params.path}/api/v1/_submissions/discussions/addMessage`, params);
export const addMessage = (data, params) =>
  post(
    `${params.path}/api/v1/_submissions/discussions/addMessage`,
    data,
    params
  );
export const deleteMessage = (data, params) =>
  post(
    `${params.path}/api/v1/_submissions/discussions/deleteMessage`,
    data,
    params
  );

export const reviewerFiles = (params) =>
  get(`${params.path}/api/v1/_submissions/reviewer/files`, params);
export const copyeditingFiles = (params) =>
  get(`${params.path}/api/v1/_submissions/copyediting/files`, params);
export const galleysFiles = (params) =>
  get(`${params.path}/api/v1/_submissions/publication/galleys`, params);
export const submissionsPublish = (data, params) =>
  post(`${params.path}/api/v1/_submissions/publish`, data, params);

// files
export const filesUpload = (data, params) =>
  post(`${params.path}/api/v1/files/upload`, data, params);
export const filesDelete = (data, params) =>
  post(`${params.path}/api/v1/files/delete`, data, params);
export const filesMetadata = (data, params) =>
  post(`${params.path}/api/v1/files/metadata`, data, params);
export const getGenresList = (params) =>
  get(`${params.path}/api/v1/files/genres`, params);
export const uploadImage = (data, params) =>
  post(`${params.path}/api/v1/files/uploadImage`, data, params);

// author
export const getAuthors = (params) =>
  get(`${params.path}/api/v1/authors`, params);
export const getAuthorsEdit = (params) =>
  get(`${params.path}/api/v1/authors/edit`, params);
export const authorsUpdate = (data, params) =>
  post(`${params.path}/api/v1/authors/update`, data, params);
export const authorsDelete = (data, params) =>
  post(`${params.path}/api/v1/authors/delete`, data, params);
