<template>
  <div class="bottom-view">
    <div class="centerBottom">
      <!-- <div>Copyright© 2024 Tech Science Press</div> -->
      <div>
        ©Copyright 2011 - 2025 by PiscoMed Publishing Pte. Ltd. All Rights
        Reserved
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bottom-view {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  background: linear-gradient(to bottom, #218ae5, #3a9aec, #63b4f6);
  color: #ffffff;
  @media (max-width: 996px) {
    width: 100vw;
    padding: 15px 0;
  }
  .centerBottom {
    width: 1400px;
    margin: auto;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    font-weight: 550;
    @media (max-width: 996px) {
      width: 100%;
      font-size: 13px;
      line-height: 30px;
    }
  }
}
</style>
