import { get, post } from "@/utils/http";
export const login = (data) => post("index/api/v1/access/login", data);
export const userLogout = (data, params) =>
  post("index/api/v1/access/logout", data, params);
export const resetPassword = (data, params) =>
  post("index/api/v1/access/resetPassword", data, params);
export const registerData = () => get("index/api/v1/access/register");
export const register = (data) => post("index/api/v1/access/register", data);

//profile
export const getIdentity = (params) =>
  get("index/api/v1/profiles/identity", params);
export const submitIdentity = (data, params) =>
  post("index/api/v1/profiles/identity", data, params);

export const getContact = (params) =>
  get("index/api/v1/profiles/contact", params);
export const submitcontact = (data, params) =>
  post("index/api/v1/profiles/contact", data, params);

export const getRoles = (params) => get("index/api/v1/profiles/roles", params);
export const submitRoles = (data, params) =>
  post("index/api/v1/profiles/roles", data, params);

export const getPublic = (params) =>
  get("index/api/v1/profiles/publicProfile", params);
export const submitPublic = (data, params) =>
  post("index/api/v1/profiles/publicProfile", data, params);
export const uploadImage = (data, params) =>
  post("index/api/v1/profiles/uploadImage", data, params);
export const deleteImage = (data, params) =>
  post("index/api/v1/profiles/deleteImage", data, params);

export const changePassword = (data, params) =>
  post("index/api/v1/profiles/changePassword", data, params);

export const getNotifications = (params) =>
  get("index/api/v1/profiles/notifications", params);
export const submitNotifications = (data, params) =>
  post("index/api/v1/profiles/notifications", data, params);

export const submitApiProfile = (data, params) =>
  post("index/api/v1/profiles/apiProfile", data, params);

//ojs
export const toOjsUrl = (params) =>
  get(`${params.path}/api/v1/access/ojs`, params);
