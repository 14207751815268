import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueMeta from "vue-meta";
import "animate.css";
// import VuePdf from 'vue-pdf';
// Vue.use(VuePdf);
// import locale from 'element-ui/lib/locale/lang/en';

Vue.use(VueMeta);
// Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
