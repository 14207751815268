<template>
  <div class="header" v-show="isShow">
    <div class="header-center">
      <div class="logo">
        <a class="logo-img">
          <img :src="imgUrl" alt="" />
        </a>
      </div>
      <div class="nav">
        <div
          id="menu"
          :class="{ navMenu: !isShowMenu, minNnavShow: isShowMenu }"
          @click="minNavShow"
        >
          <span class="a1"></span>
          <span class="a2"></span>
          <span class="a3"></span>
        </div>
        <div class="userMenu">
          <div v-if="userName === ''">
            <span><img src="@/assets/images/user.png" alt="" /></span>
            <router-link to="/login">User Home</router-link>
          </div>
          <div v-if="userName !== ''">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <img
                  src="@/assets/images/user.png"
                  style="
                    padding-right: 5px;
                    text-align: center;
                    vertical-align: middle;
                    width: 15px;
                    height: 15px;
                  "
                  alt=""
                />
                {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item command="Dashboard"
                  >Dashboard</el-dropdown-item
                > -->
                <el-dropdown-item command="profile"
                  >My Profile</el-dropdown-item
                >
                <el-dropdown-item command="Manuscript"
                  >View Manuscript</el-dropdown-item
                >
                <el-dropdown-item command="logout">Logout</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="min-nav" v-if="isShowMenu">
          <ul>
            <li
              @click="menuShow"
              v-for="(item, index) in navList"
              :key="index"
              :class="{ select: item.path == $route.path }"
            >
              <router-link :to="item.path">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
        <ul class="nav-tabs" v-else>
          <li
            v-for="(item, index) in navList"
            :key="index"
            :class="{ select: item.path == $route.path }"
          >
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
          <li v-if="userName == ''">
            <span><img src="@/assets/images/user.png" alt="" /></span>
            <router-link to="/login">User Home</router-link>
          </li>
          <li v-if="userName !== ''">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <img
                  src="@/assets/images/user.png"
                  style="
                    padding-right: 5px;
                    text-align: center;
                    vertical-align: middle;
                    width: 15px;
                    height: 15px;
                  "
                  alt=""
                />
                {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item command="Dashboard"
                  >Dashboard</el-dropdown-item
                > -->
                <el-dropdown-item command="profile"
                  >My Profile</el-dropdown-item
                >
                <el-dropdown-item command="Manuscript"
                  >View Manuscript</el-dropdown-item
                >
                <el-dropdown-item command="logout">Logout</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>

      <!-- <div class="header-right">
        <div class="icon-search" @click="toSearch">
          <i class="iconfont icon-sousuo"></i>
        </div>
        <div>
          <el-dropdown
            @command="handleCommand"
            v-if="userName !== ''"
            class="user-style"
          >
            <span class="el-dropdown-link">
              <img src="@/assets/opimg/r1.png" alt="" />
              {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Dashboard">Dashboard</el-dropdown-item>
              <el-dropdown-item command="profile">My Profile</el-dropdown-item>
              <el-dropdown-item command="logout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a class="user-style" @click="toLogin" v-else>
            <img src="@/assets/opimg/r1.png" alt="" />
            LOGIN
          </a>
          <a v-if="userName === ''" class="user-style" @click="toRegister">
            <img src="@/assets/opimg/r2.png" alt="" />
            REGISTER
          </a>
        </div>
        <div
          id="menu"
          :class="{ navMenu: !isShowMenu, minNnavShow: isShowMenu }"
          @click="minNavShow"
        >
          <span class="a1"></span>
          <span class="a2"></span>
          <span class="a3"></span>
        </div>
        <div class="min-nav" v-if="isShowMenu">
          <ul>
            <li
              @click="menuShow"
              v-for="(item, index) in navList"
              :key="index"
              :class="{ select: item.path == $route.path }"
            >
              <router-link :to="item.path">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { siteInfo } from "@/api/common";
import { userLogout, toOjsUrl } from "@/api/login";
export default {
  data() {
    return {
      imgUrl: "",
      isShow: true,
      dialogVisible: false,
      userName: "",
      isShowMenu: false,
      navList: [
        { name: "Home", path: "/" },
        { name: "Journals", path: "/journalList" },
        {
          name: "Editorial and Publishing Policies",
          path: "/editorialPublishing",
        },
        // { name: "Partners", path: "/" },
        { name: "Author Guidelines", path: "/authorGuidelines" },
        { name: "News & Announcements", path: "/announcementsList" },
        { name: "About", path: "/aboutView" },
        // { name: "User Home", path: "/login" },
      ],
    };
  },
  created() {},
  mounted() {
    this.userName = JSON.parse(localStorage.getItem("userInfo"))
      ? JSON.parse(localStorage.getItem("userInfo")).userName
      : "";
    this.getInfo();
  },
  methods: {
    menuShow() {
      this.isShowMenu = false;
    },
    minNavShow() {
      this.isShowMenu = !this.isShowMenu;
    },
    toSearch() {
      this.$router.push({
        path: "/advancedSearch",
      });
    },
    toRegister() {
      this.$router.push({
        path: "/regist",
      });
    },
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    toSubmit() {
      if (this.userName === "") {
        this.$router.push({
          path: "/login",
        });
      } else {
        if (this.$route.query.journal) {
          let rolesList = JSON.parse(localStorage.getItem("rolesList"));
          let filterRole = rolesList.findIndex(
            (item) =>
              item.path === this.$route.query.journal && item.authorChecked
          );
          if (filterRole !== -1) {
            this.$router.push({
              path:
                "/detailPages/" + this.$route.query.journal + "/submissions",
              query: {
                journal: this.$route.query.journal,
              },
            });
          } else {
            this.$router.push({
              path: "/detailPages/" + this.$route.query.journal + "/wizard",
              query: {
                journal: this.$route.query.journal,
              },
            });
          }
        } else {
          this.$router.push({
            path: "/journalList",
          });
        }
      }
    },
    getInfo() {
      // this.imgUrl = require("@/assets/images/logo.png");
      siteInfo().then((res) => {
        this.imgUrl = res.data.logo.url;
      });
    },
    logout() {
      userLogout(null, { apiToken: true }).then((res) => {
        if (res.code === 200) {
          this.$router.push({
            path: "/",
          });
          localStorage.clear();
          this.userName = "";
        }
        // else {
        //   this.userName = "";
        //   localStorage.clear();
        //   this.$router.push({
        //     path: "/login",
        //   });
        // }
      });
    },
    handleCommand(command) {
      switch (command) {
        case "Dashboard":
          this.toOjs();
          break;
        case "profile":
          this.toOjs();
          break;
        case "Manuscript":
          this.$router.push({
            path: "/manuscript",
            query: {
              journal: "index",
            },
          });
          break;
        case "logout":
          this.logout();
          break;
      }
    },
    toOjs() {
      let journal = this.$route.query.journal
        ? this.$route.query.journal
        : "index";
      toOjsUrl({ path: journal, apiToken: true }).then((res) => {
        if (res.code === 200) {
          window.open(res.data, "_blank");
        } else if (
          res.code === 401 &&
          res.messages == "The user not allow access."
        ) {
          this.$router.push({ path: "/profile" });
        }
        // else {
        //   this.$router.push({ path: "/profile" });
        // }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/iconfont/iconfont.css";
.header {
  width: 100%;
  background-color: #ffffff;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: 4px 4px 4px rgba(195, 195, 195, 0.35);
  @media (max-width: 996px) {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 5px;
    box-sizing: border-box;
  }
  .header-center {
    width: 1400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 996px) {
      width: 100vw;
    }
    .logo {
      .logo-img {
        height: 65px;
        padding-right: 10px;
        img {
          height: 65px;
          width: auto;
          transition: all 0.5s;
        }
      }
    }
    .nav {
      text-align: right;
      @media (max-width: 996px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .nav-tabs {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        @media (max-width: 996px) {
          display: none;
        }
        li {
          margin-left: 13px;
          list-style: none;
          cursor: pointer;
          padding: 9px 10px;
          font-size: 16px;
          border: 1px solid #e0e0e0;
          position: relative;
          font-weight: 550;
          border-radius: 8px;
          font-family: "Arial", "Helvetica", sans-serif;
          .el-dropdown-link {
            color: #000;
          }
          img {
            padding-right: 5px;
            text-align: center;
            vertical-align: middle;
            width: 15px;
            height: 15px;
          }
        }
        li:hover {
          color: #fff;
          // background: #147075;
          background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, #64b5f6),
            color-stop(1, #1e88e5)
          );
          .el-dropdown-link {
            color: #fff;
          }
        }
        .select {
          color: #fff;
          background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, #64b5f6),
            color-stop(1, #1e88e5)
          );
          border: 1px solid #1e88e5;
          // background: #147075;
        }
        .select:after {
          width: 100%;
        }
      }
      .navMenu {
        display: none;
        width: 30px;
        position: relative;
        @media (max-width: 996px) {
          display: block;
        }
        span {
          background-color: #004fa3;
          display: block;
          width: 30px;
          height: 2px;
          position: absolute;
          transition: all 0.5s;
        }
        .a1 {
          top: -10px;
          left: 0;
        }
        .a2 {
          display: block;
          top: -2px;
          left: 0;
        }
        .a3 {
          top: 6px;
          left: 0;
        }
      }
      .userMenu {
        display: none;
        margin-left: 20px;
        list-style: none;
        cursor: pointer;
        padding: 9px 17px;
        font-size: 16px;
        border: 1px solid #e0e0e0;
        position: relative;
        font-weight: 550;
        border-radius: 8px;
        font-family: "Arial", "Helvetica", sans-serif;
        img {
          padding-right: 5px;
          text-align: center;
          vertical-align: middle;
          width: 15px;
          height: 15px;
        }
        @media (max-width: 996px) {
          display: block;
        }
      }
      .minNnavShow {
        width: 30px;
        position: relative;
        z-index: 3000;
        span {
          height: 2px;
          background-color: #004fa3;
          display: block;
          position: absolute;
          transition: all 0.5s;
        }
        .a1 {
          top: -1px;
          width: 30px;
          transform: rotate(45deg);
        }
        .a2 {
          display: none;
        }
        .a3 {
          top: -1px;
          width: 30px;
          transform: rotate(135deg);
        }
      }
      .min-nav {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 75px;
        left: 0;
        background-color: #ffffff;
        transform: all 0.5s;
        z-index: 999;
        li {
          width: 100%;
          list-style: none;
          height: 50px;
          font-size: 16px;
          line-height: 50px;
          font-weight: bold;
          text-align: center;
          &:hover {
            background-color: #218ae5;
          }
          &:active {
            background-color: #218ae5;
          }
        }
      }
    }

    // .header-right {
    //   display: flex;
    //   align-items: center;
    //   padding-left: 10px;
    //   .icon-search {
    //     cursor: pointer;
    //     padding-right: 20px;
    //     .iconfont {
    //       color: #255d42;
    //       font-size: 23px;
    //       font-weight: bold;
    //     }
    //   }
    //   .submit {
    //     margin: 0 25px;
    //     background: #a40000;
    //     color: #fff;
    //     line-height: 40px;
    //     border-radius: 20px;
    //     width: 100px;
    //     text-align: center;
    //     cursor: pointer;
    //   }
    //   .user-style {
    //     padding-right: 20px;
    //     font-size: 14px;
    //     line-height: 25px;
    //     cursor: pointer;
    //     display: block;
    //     &:hover {
    //       color: #f19600;
    //     }
    //   }
    // }
  }
}
</style>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #218ae5 !important;
  color: #fff !important;
  border-radius: 8px;
}
</style>
