export function journalLink(item) {
  localStorage.setItem("journal", JSON.stringify(item.path));
  if (item.isToOJS) {
    return item.detailUrl;
  } else {
    const resolved = this.$router.resolve({
      path: "/detailPages/" + item.path,
      query: {
        journal: item.path,
      },
    });
    return resolved.href;
  }
}
export function articleLink(item) {
  if (item.isToOJS) {
    return `https://ojs.piscomed.com/index.php/${item.journalPath}/article/view/${item.id}`;
  } else {
    const resolved = this.$router.resolve({
      path: "/detailPages/" + item.journalPath + "/articalDetail",
      query: {
        journal: item.journalPath,
        id: item.id,
      },
    });
    return resolved.href;
  }
}
export function newsLink(item) {
  if (item.isToOJS) {
    return `https://ojs.piscomed.com/index.php/${item.journalPath}/announcement/view/${item.id}`;
  } else {
    const resolved = this.$router.resolve({
      name: "announcementsDetail",
      query: {
        detailUrl: item.url,
      },
    });
    return resolved.href;
  }
}
